<template>
  <NuxtLink
    class="studio-logo undecorated-router-link"
    :class="{ 'studio-logo--studio-name': !studio.logo }"
    :to="{ name: 'studio-home-page' }"
    v-if="studio"
  >
    <img
      class="logo"
      v-if="studio.logo && studio.logo.storage_url"
      :src="studio.logo.storage_url"
    />
    <div v-else class="studio-name">
      <h2 class="m-0">
        {{ studio.studio_name }}
      </h2>
    </div>
  </NuxtLink>
</template>

<script lang="ts">
export default {
  name: 'StudioLogo',
  props: {
    studio: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.studio-logo {
  display: inline;
  height: 100%;
  &--studio-name {
    display: inline-flex;
    align-items: center;
  }
}

.logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.studio-name {
  h2 {
    color: var(--brand-color, $coral);
  }
}
</style>
